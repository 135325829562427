.wrapper {
  display: flex;
  align-items: center;
}

.content {
  margin-left: 16px;
}

.name {
  color: $black;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.email {
  color: $black50;
  font-size: 12px;
}

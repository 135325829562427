.root {
  margin-bottom: 50px;
}

.list {
  margin-top: 30px;
}

.column {
  margin: 0 20px;
}

.error {
  color: $red;
}

.row {
  display: flex;
  align-items: flex-start;
}

.image {
  height: 40px;
  width: 40px;
}

.media {
  cursor: pointer;
  text-align: center;
}

.empty {
  margin-top: 50px;
  font-size: 14px;
  color: #939598;
  text-align: center;
}

.rightWrapper {
  display: flex;
  align-items: center;
  font-size: 18px;
  letter-spacing: -0.03em;
  font-weight: 300;
  line-height: 18px;
}

.table {
  width: 100%;
  margin-top: 60px;
}

.headerRow {
  border-bottom: 2px solid $black10;
}

.headerRow th {
  padding-bottom: 10px;
  padding-top: 10px;
}

.headerRow th:first-child {
  padding-right: 0;
  text-align: left;
}

.definitionRow {
  display: flex;
  margin-bottom: 70px;
}

.campaignInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.divider {
  color: $black20;
}

.promptFilter > div{
  display: inline-block;
  width:50%;
}

.promptFilter {
  margin-bottom: 40px;
}

.actionButton {
  margin: auto !important;
}

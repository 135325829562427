.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.url {
  margin-top: 10px;
  font-family: monospace;
}

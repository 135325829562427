.root {
  color: $black;
  margin-top: -20px;

  & i {
    color: $purple
  }
}

.content {
  position: relative;
  margin: 40px auto;
  padding: 10px 10px;
}

.heading {
  font-size: 18px;
  margin-bottom: 5px;
}

.description {
  margin-top: 0px;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 20px;
  max-width: 650px;
}

.closed {
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.expand {
  font-size: 13px;
  padding-top: 10px;
}

.collapse {
  font-size: 13px;
  margin-top: -10px;
}

.definitionRow {
  margin-bottom: 10px;
}

@media (max-width: $mainContentWidth) {
  .root {
    margin-left: 0;
    margin-right: 0;
  }
}

.noData {
  margin-bottom: 20px;
}

.insightsLink {
  font-size: 20px;
  margin: 5px 0 20px;
  cursor: pointer;
}

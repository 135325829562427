.root {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
  flex: 1 0 auto;
}

.body {
  flex: 1 0 100%;
}

.info {
  margin-left: 20px;
  max-width: 400px;
}

.username {
  font-size: 28px;
  line-height: 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 16px;
}

.stats {
  display: flex;
  margin-bottom: 16px;
}

.mediaContainer {
  padding: 0 10px;  /* leave space for scrollbar */
  overflow-y: hidden;
  margin-bottom: 40px;
}

.rowWrap {
  justify-content: center;

}

.viewMore {
  margin: 20px 0;
  color: $purple;
}

/*
 * Base CSS style
 */
input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  outline: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}


/*
 * Styling the Thumb
 */
/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid $purpleLight;
  transition: all ease-in .05s;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background: $gold70;
  cursor: pointer;
  margin-top: -5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

input[type=range]:active::-webkit-slider-thumb {
  background: $purple;
  -webkit-transform: scale(1.3);
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  border: 1px solid $purpleLight;
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  background: $gold70;
  cursor: pointer;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, ;
}

input[type=range]:active::-moz-range-thumb {
  background: $purple;
  -ms-transform: scale(1.3);
}

input[type=range]::-moz-focus-outer {
  border: 0;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: 1px solid $purpleLight;
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  background: $gold70;
  cursor: pointer;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

input[type=range]:active::-ms-thumb {
  background: $purple;
  transform: scale(1.3);
}


/*
 * Styling the Track
 */
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.3rem;
  cursor: pointer;
  background: $black5;
  border-radius: 0.3rem;
}

input[type=range]:active::-webkit-slider-runnable-track {
  background: $black10;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 0.3rem;
  cursor: pointer;
  background: $black5;
}

input[type="range"]::-moz-range-progress {
  background: $gold70; 
}

input[type="range"]:active::-moz-range-progress {
  background: $purple; 
}

input[type=range]::-ms-track {
  width: 100%;
  height: 0.3rem;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: $gold70;
  border-radius: 2.6px;
}

input[type=range]:active::-ms-fill-lower {
  background: $purple;
}

input[type=range]::-ms-fill-upper {
  background: $black5;
  border-radius: 2.6px;
}

input[type=range]:active::-ms-fill-upper {
  background: $black10;
}


/*
 * Class styles
 */
.minMaxValues {
  display: flex;
  color: $black10;
}

.minValue {
  margin-left: 4px;
  flex: 1;
}

.value {
  color: $black30;
  margin-top: 5px;
  flex: 2;
  display: flex;
  justify-content: center;
}

.maxValue {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

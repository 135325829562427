.root {
  margin: 50px auto;
  max-width: $mainContentWidth;
  width: 100%;
}

.searchBox {
  display: flex;
  flex-direction: column;
}

.newButton {
  align-self: flex-end;
}

.loaderBox {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;
}

.noAdvertisers {
  text-align: center;
  margin-top: 80px;
  font-size: 20px;
}

.noAdvertisersString {
  font-style: italic;
}

.filters {
  display: flex;
}

.filterCheckbox {
  margin: 0px 5px;
}

.filterItemCount {
  font-weight: $bold;
  margin-left: 5px;
}

.advertiserCount {
  text-align: left;
  font-size: 14px;
  text-transform: lowercase;
  font-variant: small-caps;
  color: $black40;
  margin-bottom: 30px;
  font-weight: 500;
  margin: 30px 0;
}

@media (max-width: 480px) {
  .newButton {
    display: none;
  }
}

.root {
  margin: 0 auto;
  width: $mainContentWidth;
  padding-top: 30px;
}

@media print {
  .root {
    width: 100%;
    page-break-inside: avoid;
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.takumiHeader {
  display: inline-block;
  width: 40%;
}

.heading {
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
  color: #000000;
  margin-bottom: 30px;
}

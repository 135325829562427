.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $black10;
  user-select: none;
  box-sizing: content-box;
  position: relative;
}

.root a {
  display: flex;
}

.root img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.lightBackground {
  background: $black5;
}

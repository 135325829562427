.column {
  border-right: 1px solid $black10;
  padding-left: 50px;
  padding-right: 0px;
  flex: 1;
}
.column:first-child {
  padding-left: 0;
}
.column:last-of-type {
  border-right: none;
}

.columnHeader {
  padding-bottom: 20px;
  font-size: 14px;
  color: $black30;
}

.root {
  display: flex;
  align-items: center;
}

.profilePicture {
  flex: 0 0 80px;
}

.info {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.influencerUsername {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.gigTimeline {
  font-size: 12px;
  color: $black30;
}

.overdue {
  color: $red;
}

.root {
  display: flex;
  flex-direction: column;
}

.rootWrapper {
  padding: 12px;
  margin: 10px 0;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
  width: 400px;
}


.prompt {
  padding: 10px 0;
  color: $purple;
  border-bottom: 1px solid $purple;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.answer {
  margin-bottom: 30px;
  font-size: 14px;
}

.multipleAnswer {
  margin-left: 10px;
}

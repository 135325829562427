.root {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.cell {
  padding: 6px 10px;
  text-align: left;
}

.empty {
  color: $black30;
}

.input {
  border: 0;
  border-bottom: 2px solid $black20;
  font-size: 21px;
  padding: 2px 70px 0px 0px;
  margin-right: 60px;
  display: inline;
}
.event {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profilePicture {
  flex: 0 0 40px;
  margin-right: 13px;
}

.date {
  color: $black50;
  font-weight: $roman;
  margin-left: 5px;
  font-size: 15px;
}

.eventText {
  font-size: 15px;
  font-weight: $light;
}

.text-disable {
  color: $red;
  border-color: $red;
}

.text-verify {
  color: $green;
  border-color: $green;
}

.text-activate {
  color: $purple;
  border-color: $purple;
}
:global(.theme-influenceM) .text-activate:focus {
  color: $influenceMYellow;
  border-color: $influenceMYellow;
}


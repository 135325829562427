.root {
  margin-bottom: 50px;
}

.list {
  margin-top: 30px;
}

.column {
  margin: 0 20px;
}

.error {
  color: $red;
}

.row {
  display: flex;
  align-items: flex-start;
}

.image {
  height: 40px;
  width: 40px;
}

.media {
  cursor: pointer;
  text-align: center;
}

.followers {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.empty {
  margin-top: 50px;
  font-size: 14px;
  color: #939598;
  text-align: center;
}

.rightWrapper {
  display: flex;
  align-items: center;
  font-size: 18px;
  letter-spacing: -0.03em;
  font-weight: 300;
  line-height: 18px;
}

.table {
  width: 100%;
  margin-top: 60px;
}

.headerRow {
  border-bottom: 2px solid $black10;
}

.headerRow th {
  padding-bottom: 10px;
  padding-top: 10px;
}

.headerRow th:first-child {
  padding-right: 0;
  text-align: left;
}

.footerRow {
  border-top: 2px solid $black10;
  border-bottom: 1px solid $black5;
  color: #939598;
}

.footerRow th {
  padding-bottom: 10px;
  padding-top: 10px;
}

.footerRow th:first-child {
  padding-right: 0;
  text-align: left;
}

.footerRight60Pad {
  text-align: right;
  padding-right: 60px;
}

.time {
  color: $black40;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: $light;
  font-size: 16px;
  margin: 0 5px;
}

.campaignInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.rejectAll {
  text-align: center;
}

.profile {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.profilePictureBoxWrapper {
  display: flex;
  align-items: center;
}

.profilePictureBox {
  position: relative;
  cursor: pointer;
}

.info {
  margin-left: 16px;
  text-align: left;
}

.lessThanOne {
  margin-left: 10px;
  min-height: 20px;
}

.percWrapper {
  display: flex;
}

.percWrapper > h3 {
  margin-top: 5px;
}

.percSelect {
  width: 100px;
  margin-left: 10px;
}

.topCountries {
  display:flex;
  > div:first-child {
    margin: auto;
    margin-right: 0;
  }
  > div:nth-child(2) {
    min-width: 50px;
    margin: auto;
    margin-left:0;
    text-align: left;
  }
}

.actions {
  display:flex;
}

.actionButton {
  margin: auto !important;
}

.bold {
  font-weight: 600;
}

.recentPostsStats {
  display: flex;
  font-size: 12px;
}

.statIcon {
  font-size: 14px;
  margin-top: 3px;
  margin-left: 4px;
}

.toggle {
  margin-left: 16px;
}
.photoWrapper {
  float: right;
  position: absolute;
  bottom: 6px;
  right: 0;
}

.photo {
  border-radius: 2px;
  height: 42px;
}

.field {
  margin-bottom: 10px;
  position: relative;
}

.instagramLoader {
  padding-top: 8px;
  margin-right: 5px;
}

.lessWidth {
  width: calc(100% - 52px);
}

.loader {
  margin-bottom: 10px;
}

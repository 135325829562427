.root {
  height: 100px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 5px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  background-color: $black5;
  cursor: pointer;
}

.enabled {
  background-color: $purpleLight;
}

.title {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  color: $black50;
}

.disabledTitle {
  color: $black20;
}

.enabledTitle {
  color: $purple;
  margin-right: 15px;
  margin-left: 15px;
}

.tagGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 10px;
}

.horizontal {
  flex-direction: row;
  height: auto;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 15px;
  .title, .enabledTitle {
    margin-top: 0;
    margin-right: 15px;
    margin-left: 0;
    text-align: center;
    flex: 1;
  }
}

.logo {
  display: block;
  margin: auto;
}

.header {
  color: $black70;
  font-weight: $bold;
  font-size: 26px;
  margin-top: 24px;
  text-align: center;
}

.subtitle {
  color: $black70;
  font-weight: $roman;
  margin-top: 24px;
  text-align: center;
  font-size: 20px;
}

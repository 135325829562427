.root {
  background: $black;
  color: $white;
  cursor: pointer;
  font-size: 16px;
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: 700;
  line-height: 1;
  padding: 10px 80px 12px;
  will-change: background;
  transition: background 200ms;
  margin: 10px 0;
  border: 0;
  white-space: nowrap;
  align-items: center;
}
:global(.theme-influenceM) .root {
  background: $influenceMYellow;
  border-color: color($influenceMYellow shade(5%));
}

.root:hover {
  background: color($black lightness(-5%));
}
:global(.theme-influenceM) .root:hover {
  background: color($influenceMYellow lightness(-5%));
}

.root[disabled] {
  cursor: not-allowed;
  background: $black20;
  border-color: $black20;
}
.root[disabled]:hover {
  background: $black20;
}

.outline {
  composes: root;
  background: transparent;
  border: 1px solid $purple;
  color: $purple;
  padding: 10px 80px 12px;
  font-weight: normal;
}

.outline.outline:hover {
  background: $white;
}

.outline[disabled] {
  background: $white;
  border: 1px solid $black70;
  color: $black70;
}

:global(.theme-influenceM) .outline {
  background: transparent;
  border-color: color($influenceMYellow shade(5%));
  color: $influenceMYellow;
}

/* Use double-class hack to increase specifity to override theme styles */
.dangerOutline.dangerOutline {
  border: 1px solid $red;
  color: $red;
}

.grayOutline.grayOutline {
  border: 1px solid $black70;
  color: $black70;
}

.inline[disabled] {
  color: $black20 !important;
}

.inline[disabled]:hover {
  text-decoration: none !important;
}

.inline.inline {
  border: none;
  background: transparent;
  color: $black;
  margin: 0 10px;
  padding: 0;
  border-radius: 0;
  will-change: color;
  transition: color 0.3s;
}
:global(.theme-influenceM) .inline {
  background: transparent;
  color: $influenceMYellow;
}

.inline.inline:hover {
  background-color: transparent;
  color: $black;
  text-decoration: underline;
}
:global(.theme-influenceM) .inline:hover {
  background-color: transparent;
  color: $influenceMYellow;
}

.block.block {
  width: 100%;
}

.large.large {
  padding: 15px 100px 17px;
  font-size: 16px;
  box-shadow: 0 2px 0 0 color($black lightness(-20%));
  white-space: nowrap;
}
:global(.theme-influenceM) .large {
  box-shadow: 0 2px 0 0 color($influenceMYellow lightness(-20%));
}

.small.small {
  font-size: 14px;
  padding: 5px 10px 7px;
}

.gray.gray {
  color: white;
  background-color: $black70;
  border: 1px solid $black70;
}

.gray.gray:hover {
  background: color($black70 lightness(-5%));
}

.lightGold.lightGold {
  color: $black;
  background-color: $black5;
  border: 1px solid $black70;
}
.lightGold.lightGold:hover {
  color: $black;
  background-color: $white;
  border: 1px solid $black;
}

.white.white {
  color: $black;
  background: color($white a(90%));
  border: 1px solid $white;
}
:global(.theme-influenceM) .white {
  color: $influenceMYellow;
  background: color($white a(90%));
  border: 1px solid $white;
}

.white.white:hover {
  background: $white;
  border: 1px solid $white;
  color: $black;
}
:global(.theme-influenceM) .white:hover {
  color: $influenceMYellow;
  background: $white;
  border: 1px solid $white;
}

.transparent.transparent {
  color: $black90;
  border: 1px solid $black90;
  background-color: transparent;
  padding: 4px 40px 6px;
  transition: background-color 200ms, color 200ms;
}

.transparent.transparent:hover {
  background-color: $black90;
  color: $white;
  box-shadow: $boxShadowBase;
}

.facebookBlue.facebookBlue {
  color: white;
  background-color: $facebookBlue;
}

.facebookBlue.facebookBlue:hover {
  background-color: color($facebookBlue lightness(-5%));
}

.submitButton {
  transition: all ease 0.8s;
}

.success {
  box-shadow: inset 0 0 100px 100px $green;
}

.failure {
  box-shadow: inset 0 0 100px 100px $red;
}

.inline.success {
  box-shadow: none;
  color: $green;
}

.inline.failure {
  box-shadow: none;
  color: $red;
}

.noMargin {
  margin: 0 !important;
}

.danger {
  color: $red !important;
}

.playlistRow {
  padding: 10px;
  display: flex;
  .username {
    color: $purple;
  }
  > div {
    display: inline-block;
    text-align: left;
    float: left;
  }
  > :nth-child(2) {
    margin-left: 20px;
  }
}

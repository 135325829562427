.root {
  flex: 1;
  margin-left: 30px;
  width: 430px;
  height: 240px;
  position: relative;
  margin: 100px 20px 0 20px;
}

.photos {
  /*border: 2px dashed $purple;*/
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cover {
  width: 175px;
  height: 175px;
  /*border: 2px dashed $purple;*/
  background-color: white;
  position: absolute;
  top: -90px;
  left: 20px;
}

.uploadedCover {
  width: 177px;
  height: 177px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 2px 20px rgba(5, 5, 5, 0.3);
}
.uploadedCover img {
  width: 176px;
  height: 176px;
  object-fit: cover;
}

.coverExplanation {
  color: $black;
  text-align: center;
  width: 130px;
  margin: 60px auto;
  font-size: 17px;
}
.arrow {
  font-size: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.info {
  color: black;
  width: 200px;
  position: absolute;
  top: 120px;
  left: 190px;
  font-size: 13px;
}
.info > strong {
  display: block;
}

.mainExplanation {
  text-align: center;
  position: absolute;
  font-size: 12px;
  bottom: 65px;
  left: 0;
  right: 0;
  color: $black;
}

.mainExplanation.hasImages {
  bottom: -55px;
}

.photosList {
  position: absolute;
  left: -20px;
  right: 0;
  bottom: 15px;
  width: 470px;
  height: 125px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.photosList div {
  position: relative;
  display: inline-block;
}

.photosList img {
  height: 100px;
  margin: 12px;
  box-shadow: 0 2px 20px rgba(5, 5, 5, 0.3);
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
}

.photosList div:hover .deletePhoto {
  display: block;
}

.photosList .deletePhoto {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  user-select: none;
}

.photosList .deletePhoto:hover {
  cursor: pointer;
}

.uploadedCover:hover .deletePhoto {
  display: block;
}

.uploadedCover .deletePhoto {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  user-select: none;
}

.uploadedCover .deletePhoto:hover {
  cursor: pointer;
}

.active {
  animation: active 1s;
  animation-iteration-count: infinite;
}

.canDrop > .uploadedCover,
.canDrop > .photosList {
  pointer-events: none;
  opacity: 0.1;
}

.leftScrollIndicator {
  position: absolute;
  left: -20px;
  bottom: 15px;
  height: 125px;
  width: 20px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
}
.rightScrollIndicator {
  position: absolute;
  right: -23px;
  bottom: 15px;
  height: 125px;
  width: 20px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 95%,
    rgba(255, 255, 255, 1) 100%
  );

  z-index: 10;
}

@keyframes active {
  0% {
    background-color: white;
  }
  50% {
    background-color: $black5;
  }
  100% {
    background-color: white;
  }
}

.marchingAnts {
  background-size: 9px 1px, 9px 1px, 0.5px 9px, 1px 9px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  -webkit-animation: marchingAnts 1s;
  animation: marchingAnts 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;

  background-image: -webkit-linear-gradient(left, white 50%, $grey05 50%),
    -webkit-linear-gradient(left, white 50%, $grey05 50%), -webkit-linear-gradient(top, white 50%, $grey05 50%),
    -webkit-linear-gradient(top, white 50%, $grey05 50%);
  background-image: linear-gradient(to right, white 50%, $grey05 50%), linear-gradient(to right, white 50%, $grey05 50%),
    linear-gradient(to bottom, white 50%, $grey05 50%), linear-gradient(to bottom, white 50%, $grey05 50%);
}
.marchingAnts.marching {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
@keyframes marchingAnts {
  0% {
    background-position: 0 0, 0 100%, 0 0, 100% 0;
  }
  100% {
    background-position: 30px 0, -30px 100%, 0 -30px, 100% 30px;
  }
}

.root {
  display: flex;
  flex-direction: row;
}

.header {
  display: flex;
  flex: 1;
}

.container {
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

.left {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 40px;
  min-width: 400px;
}

.media {
  max-width: 100%;
  max-height: 100%;
}

.right {
  display: flex;
  flex-grow: 1;
}

.arrow {
  margin-top: 200px;
  display: flex;
  flex: 0 0 27px;
  align-items: flex-start;
}

.leftArrow {
  cursor: pointer;
}

.rightArrow {
  cursor: pointer;
}

.body {
  margin: 20px 0;
}

.disable {
  visibility: hidden;
}

.click {
  cursor: pointer;
}

.active {
  background-color: $white;
}

.templateInstructions {
  margin: 10px;
}

.templateItem {
  border: 1px solid $purple;
  border-radius: 5px;
  padding: 20px;
  display:flex;
  margin: 5px;
  cursor: pointer;
}

.templateItem:hover {
  background-color: $purpleLight;
}

.templateText{
  font-size: 24px;
  margin-left: 10px;
  line-height:20px;
  text-transform: capitalize;
}

.clearEditorButton {
  margin: 5px;
  margin-left: auto;
  width: 60px;
  color: $purple;
  border: 1px solid $purple;
  border-radius: 5px;
  padding: 1px 7px;
  cursor: pointer;
  text-align: center;
}

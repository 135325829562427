.ce-delimiter {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.ce-delimiter:before {
  display: block;
  content: "";
  border-bottom: 2px solid $black5;
  flex: 1;
  margin-right: 20px;
  margin-bottom: 12px;
}

.ce-delimiter:after {
  display: block;
  content: "";
  border-bottom: 2px solid $black5;
  flex: 1;
  margin-left: 20px;
  margin-bottom: 12px;
}
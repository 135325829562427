root {
}

.heading {
  font-weight: $bold;
  font-size: 22px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
  display: flex;
  margin-bottom: 18px;
  flex-flow: row;
  height:50px;
}

.messageBox {
  display: flex;
  align-items: center;
  margin-bottom:40px;
}

.message {
    width:100%;
}

.notifyButton {
  flex: 1;
  margin-bottom: auto;
  margin-top: auto;
  text-align: right;
  margin-left: 20px;
}

.messageLabel {
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: $bold;
  font-size: 12px;
}

.message {
  font-weight: $light;
  font-size: 25px;
  line-height: 1.2em;
  color: $black90;
}

.messagePlaceholder {
  color: $black20;
}

.modal {
  width: 360px;
}

.messageInputBox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.messageError {
  margin-bottom: 4px;
  color: red;
  font-weight: $bold;
  font-variant: small-caps;
  text-transform: lowercase;
}

.messageInfo {
  font-size: 12px;
  width: 280px;
}

.playlistWrapper {
  display: flex;
  flex-direction: column;
}

.checkbox {
  margin-top: 30px;
}

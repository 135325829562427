/* Example Styles for React Tags*/
:global .ReactTags__tags {
  position: relative;
}

/* Styles for the input */
:global .ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}
:global .ReactTags__tagInput input.ReactTags__tagInputField,
:global .ReactTags__tagInput input.ReactTags__tagInputField:focus {
  border: none;
  outline: none;
  border-bottom: 2px solid $black10;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2em;
  padding: 8px;
  margin-bottom: 8px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: 100%;
}

:global .ReactTags__selected {
  margin: 0;
}

/* Styles for selected tags */
:global .ReactTags__selected span.ReactTags__tag {
  background-color: $purpleLight;
  border: none;
  color: $purple;
  display: inline-block;
  font-size: 14px;
  font-weight: $bold;
  line-height: 1;
  margin: 0 10px 10px 0;
  outline: none;
  padding: 4px 16px;
}
:global .theme-influenceM .ReactTags__selected span.ReactTags__tag {
  color: $influenceMYellow;
}

:global .ReactTags__selected .ReactTags__remove {
  color: white;
  font-weight: $bold;
  font-size: 24px;
  line-height: 21px;
  height: 24px;
  cursor: pointer;
  margin-left: 15px;
  margin-right: -13px;
  width: 24px;
  display: inline-block;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  transition: background 200ms;
  vertical-align: middle;
  border: none;
}

:global .ReactTags__selected .ReactTags__remove:hover {
  background-color: $black;
}

/* Styles for suggestions */
:global .ReactTags__suggestions {
  position: absolute;
  font-size: 16px;
  z-index: 100;
}
:global .ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
  max-height: 200px;
  overflow-y: scroll;
}
:global .ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
:global .ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
:global .ReactTags__suggestions ul li.active {
  background: $black10;
  color: $purple;
  cursor: pointer;
}
:global .theme-influenceM .ReactTags__suggestions ul li.active {
  color: $influenceMYellow;
}
:global .ReactTags__suggestions li.active mark {
  color: $purple;
}
:global .theme-influenceM .ReactTags__suggestions li.active mark {
  color: $influenceMYellow;
}

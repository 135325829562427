.root {
  overflow: hidden;
  position: relative;
}

.filterWell {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 20px;
  position: relative;
  min-height:250px;
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: $black20;
  z-index: 1;
}

.content {
  width: 100%;
  padding: 50px;
  padding-top: 40px;
  padding-bottom: 30px;
  background-color: $black5;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #f8f8f8;
}

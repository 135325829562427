.container {
  padding: 15px;
  border: 2px solid $red;
  border-radius: 8px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
}

.header {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -15px;
  left: 0;
  right: 0;
}

.headerContent {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  color: $red;
  font-style: italic;
  font-weight: 600;
}

.content {
  outline: none;
}
.heading {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  user-select: none;
}


.item {
  display: flex;
  padding: 0 10px;
  box-sizing: content-box;

  .text {
      &::before {
        content: 'Don\'t: ';
        font-weight: 600;
      }
      outline: none;
      flex-grow: 1;
      padding: 10px 0;
  }
}



.checkbox {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 10px;
  height: 10px;
  margin: 15px 15px 10px 0;
  border-radius: 50%;
  background: $red;
  cursor: pointer;
  user-select: none;

  &:hover {
      opacity: 0.9;
  }
}


.checked {
  .checkbox {
      background: $green;
      border-color: none;

      &:hover {
        opacity: 0.9;
      }

      &::after {
          opacity: 1;
      }
  }
  .text {
    &::before {
      content: 'Do: ';
      font-weight: 600;
    }
  }
}
.mediaInfo a {
  margin-right: 0.3em;
  font-weight: 600;
}
:global(.theme-influenceM) .mediaInfo a {
  color: $influenceMYellow;
}

.mediaInfo {
  color: #262626;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  flex: 1 0 auto;
}

.metaFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  margin-top: auto;
}

.postedTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  margin-left: 5px;
}

.caption {
  overflow-y: auto;
  word-wrap: break-word;
  margin-top: 3px;
  max-width: 290px;
  height: 100%;
}

.iconWithValue {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.count {
  margin-left: 5px;
}

.storyFrameInsights {
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .heading {
    font-weight: 700;
  }
  .storyFrameInsight {
    margin-left: 10px;
    flex-direction: row;
    display:flex;
    padding-top: 5px;
    padding-bottom: 5px;
    .frameThumb {
      width: 50px;
      height: 90px;
      margin-right:10px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .frameNumber {
      font-size: 12px;
      font-weight: 700;
      color: $purple;
    }
    .storyInsightValue {
      span:first-child {
        color: $black80;
        font-weight: 700;
      }
      font-size: 12px;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  flex: 100%;
  box-shadow: 0px 0px 20px 0px $black40;
}

.center {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.loginLink {
  margin-top: 10px;
  text-align: center;
}

.loginLink a {
  color: $purple;
}
:global(.theme-influenceM) .loginLink a {
  color: $influenceMYellow;
}

.root {
  align-items: flex-start;
  opacity: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  background-color: color($black a(50%));;
  min-height: 100vh;
  padding: 24px;
  position: fixed;
  right: 0;
  top: 0;
  transition: visibility 0ms linear 100ms, opacity 100ms linear;
  visibility: hidden;
  width: 100%;
}

.isOpen {
  opacity: 1;
  transition: visibility 0ms linear 0ms, opacity 100ms linear;
  visibility: visible;
  z-index: 1000000;
}

.box {
  background: $white;
  border-radius: 2px;
  box-shadow: 0 0 16px rgba(0, 0, 0, .1);
  max-width: 740px;
  margin-top: 30px;
  opacity: 0;
  transition: opacity 100ms linear, transform 0ms ease 100ms;
  transform: scale(0.9);
  position: relative;
}

@media (max-height: 765px) {
  .box {
    margin-top: 0px;
  }
}

.isOpen .box {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms linear 50ms, transform 100ms ease 50ms;
}

.close {
  position: absolute;
  right: 24px;
  top: 28px;
  cursor: pointer;
  color: $purple;
  font-size: 14px;
  z-index: 10000001;
}
:global(.theme-influenceM) .close {
  color: $influenceMYellow;
}

.arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.leftArrow {
  composes: arrow;
  left: -45px;
  padding-right: 18px;
}

.rightArrow {
  composes: arrow;
  right: -45px;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

@media (min-width: $bigScreen) {
  .box {
    max-width: 970px;
  }
}

.root {
  display: flex;
  flex-direction: column;
}

.input {
  border: none;
  border-bottom: 2px solid $black10;
  color: #000000;
  font-size: 18px;
  line-height: 3em;
  margin-bottom: 8px;
  transition: all $transitionTimeBase;
  width: 100%;
}

.input[type=number] {
  -moz-appearance: textfield;
}

.goldBorder {
  border-bottom-color: $purpleLight;
}

.input:focus {
  border-bottom-color: $purple;
  outline: 0;
}

.dark {
  background-color: $black;
  color: $white;
  line-height: 1.2em;
  padding: 8px 0;
  font-size: 14px;
}

.description {
  color: $black40;
  font-size: 12px;
  font-weight: 500;
}

.error,
.error:focus {
  border-bottom: 2px solid $red;
}

.inputContainer {
  position: relative;
}

.inputWithPrefix {
  padding-left: 30px;
}

.inputWithSmallPrefix {
  margin-left: -30px;
}

.inputWithPostfix {
  padding-right: 30px;
}

.inputWithSmallPostfix {
  margin-left: -30px;
}

.bigInput {
  font-size: 28px;
  line-height: 2.4em;
}

.smallInput {
  font-size: 16px;
  line-height: 1.4em;
}

.lightText {
  font-size: 14px;
  line-height: 1.2em;
  color: $black50;
}

.prefix {
  position: absolute;
  left: 0;
  top: 14px;
  font-size: 18px;
  color: $black40;
  text-align: right;
  width: 15px;
}

.bigPrefix {
  font-size: 28px;
}

.postfix {
  position: absolute;
  right: 0;
  top: 14px;
  font-size: 18px;
  color: $black40;
  text-align: right;
}

.bigPostfix {
  font-size: 28px;
}

.smallPostfix,
.smallPrefix {
  font-size: 16px;
  line-height: 0;
  margin-top: -3px;
}

:global(.theme-influenceM) .input:focus {
  border-bottom-color: $influenceMYellow;
}

.disabled {
  color: $black10 !important;
  border-bottom: 2px solid $black5 !important;
  cursor: disabled;
}
.disabledFix {
  color: $black10 !important;
}

.centeredCursor {
  text-align: center;
}

.bigCentered {
  font-weight: 300;
  font-size: 28px;
  line-height: 1.7em;
  width: calc(100% - 60px);
}

.rightCursor {
  text-align: right;
}

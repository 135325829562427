.breakDownBar {
  display: flex;
  width: 100%;
  position: relative;
}

.verified {
  color: $purple;
  border-color: $purple;
}
:global(.theme-influenceM) .verified {
  color: $influenceMYellow;
  border-color: $influenceMYellow;
}


.eligible {
  color: color($purple a(61%));
}
:global(.theme-influenceM) .eligible {
  color: color($influenceMYellow a(61%));
}


.total {
  text-align: right;
  color: $black20;
  border-color: $black20;
}

.number {
  font-size: 24px;
  font-weight: $bold;
  padding-right: 5px;
}

.bar {
  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;
  display: flex;
}

.bar .verified {
  border-bottom: 6px solid;
  border-color: $purple;
  min-width: 3px;
}
:global(.theme-influenceM) .bar .verified {
  border-color: $influenceMYellow;
}

.bar .eligible {
  border-bottom: 6px solid;
  border-color: color($purple a(61%));
  min-width: 3px;
}
:global(.theme-influenceM) .bar .eligible {
  border-color: color($influenceMYellow a(61%));
}

.bar .total {
  border-bottom: 6px solid;
  border-color: $black20;
}

.targetingDetails {
  margin-top: 50px;
}

.description {
  font-size: 12px;
  color: $black90;
  margin-top: 15px;
  padding-right: 5px;
  white-space: nowrap;
}

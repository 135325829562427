.awaitingSubmission {
  margin: -5px 15px -35px 0;
  display: flex;
  justify-content: flex-end;
}

.count {
  margin-left: 5px;
  font-weight: $bold;
}

.root {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.left {
  display: flex;
}

.right {
  display: flex;
}

.root {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.header {
  display: flex;
  flex: 1;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.instructions {
  margin-top: 30px;
  margin-bottom: 16px;
}

.media {
  max-width: 100%;
  max-height: 100%;
}

.right {
  display: flex;
  flex-grow: 1;
}

.body {
  margin: 20px 0;
}

.disable {
  visibility: hidden;
}

.click {
  cursor: pointer;
}

.active {
  background-color: $white;
}

.post {
  transition: all 200ms;
  will-change: transform;
}

.post:hover {
  transform: translate3d(0 -3px 0);
  box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, 0.05);
}

.selected {
  border: 3px solid $purple;
  margin: -3px;
}

.partOfAnotherStory {
  border: 3px solid $red;
  margin: -3px;
}

.selectedFrames {
  height: 600px;
  overflow: auto;
  padding: 10px;
}

.tags {
  color: $purple;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.frameCard {
  width: 200px;
}

.locationIcon {
  font-size: 16px;
  margin-left: -3px;
}

.swipeUpLink {
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
}

.verifyButton {
  position: absolute;
  top: -22px;
  right: 0px;
}

.verifiedText {
  color: $green;
  position: absolute;
  top: -10px;
  right: 0px;
  padding: 10px;
}

.noStoryFrames {
  text-align: center;
}

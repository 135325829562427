.root {
  display: flex;
  justify-content: space-between;
  margin: 64px 0;
}

.compare {
  align-self: center;
  margin: 0 16px;
}

.explanation {
  margin-top: 25px;
}

.explanationcontent {
  color: $black;
  font-weight: 600;
  font-size: 18px;
}

.link {
  text-decoration:underline
}

.next {
  cursor: pointer;
  margin-left: 15px;
}

.nav {
  display: inline-flex;
  padding-left: 270px;
}

.dots {
  bottom: -38px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  background-color: black;
  margin-left: 6px;
  margin-right: 6px;
}

.activeDot {
  background-color: #9B51E0;
}

.headingWrapper {
  margin-bottom: 70px;
}

.subHeading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.section {
  margin-bottom: 40px;
}

.selfTagSection {
  margin-bottom: 80px;
}

.followersInput {
  width: 300px;
}

.root {
  display: flex;
}

.targetingForm {
  flex: 1;
}

.targetingEstimate {
  flex: 0 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.box {
  position: fixed;
  width: 380px;
  flex-direction: row;
  margin-top: 20px;
  border: 1px solid $black10;
  padding: 40px;
  max-width: 1080px;
  margin-left: auto;
}

.spacer {
  height: 1px;
  background-color: $black10;
  margin-top: 15px;
  margin-bottom: 25px;
}

.boxTitle {
  font-size: 20px;
  font-weight: 600;
}

.boxSubTitle {
  font-size: 14px;
  color: $black30;
  font-weight: 600;
  padding-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
}

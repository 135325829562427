.root {
  display: flex;
  flex-direction: column;
  flex: 100%;
}

.center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
}

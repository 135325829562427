.root {
  position: relative;
}

.campaignCount {
  text-align: left;
  font-size: 14px;
  text-transform: lowercase;
  font-variant: small-caps;
  color: $black40;
  font-weight: 500;
}

.advertiser {
  display: flex;
  align-items: center;
}

.advertiserName {
  margin-left: 20px;
}

.picture {
  display: flex;
  justify-content: center;
  width: 90px;
}

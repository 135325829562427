.root {
  vertical-align: top;
  margin-bottom: 40px;
}

.instructions {
  font-size: 14px;
  margin-bottom: 20px;
  white-space: pre-line;
  padding-right: 50px;
}

.leftColumn {
  display: inline-block;
  width: 70%;
  vertical-align: top;
}

.rightColumn {
  display: inline-block;
  width: 30%;
  vertical-align: top;
}

.postInfo {
  margin-bottom: 20px;
  display: flex;
}

.sectionHeading {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  font-variant: small-caps;
  text-transform: lowercase;
}

.dashHeading {
  font-weight: 200;
}

.hashTags {
  margin-bottom: 15px;
}

.brief {
  max-width: 600px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  font-weight: normal;
}

h1 {
  font-weight: $bold;
}

input[type="password"] {
  font-family: $fontFamily;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  color: color($black a(80%));
  background-color: $white;
  font-size: 16px;
  font-weight: $roman;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $fontFamily;
}

a {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: normal;
  font-weight: $bold;
}

/* for sticky footer */
.app {
  display: flex;
  min-height: 100vh;
}

.content {
  background-color: $white;
  display: flex;
  flex: 1;
  margin: 0 auto;
  width: 1130px;
}

img {
  max-width: 100%;
}

@media print {
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfbO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v15/CrYjSnGjrRCn0pd9VQsnFOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOLO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
  }

  html, body {
    width: 210mm;
    height: 297mm;
    font-family: 'Roboto';
    margin: 0;
  }

  @page {
    size: A4;
    margin: 10mm;
  }

  .content {
    display: block;
    width: 100%;
  }
}

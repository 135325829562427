.root {
  background-color: #F5F5F5;
  display: flex;
  min-height: calc(100vh - $topNavHeight - $secondaryNavHeight);
}

.sidebar {
  flex: 0 0 240px;
  background-color: #FFFFFF;
  overflow: auto;
}

.sidebarContainer {
  position: fixed;
  width: 240px;
}

.main {
  padding: 20px;
  margin-bottom: 50px;
  flex: 1;
}

.widgets {
  flex: 0 0 350px;
  padding: 20px 20px 0 0;
}

.widgetContainer {
  position: fixed;
  width: 330px;
}

.widgetLink {
  display: block;
  text-decoration: underline;
  margin-bottom: 10px;
}

.widgetDetail {
  font-size: 12px;
  color: $black50;
}

.archiveButton {
  display: block;
  outline: none;
  border: none;
  background: none;
  font-size: inherit;
  color: $red;
  margin: 5px 0;
  cursor: pointer;
}

.archiveButton:hover {
  color: darken($red 10%);
}

.block {
  padding: .7em 0;
}

.divider {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.divider:before {
  display: block;
  content: "";
  border-bottom: 2px solid $black5;
  flex: 1;
  margin-right: 20px;
  margin-bottom: 12px;
}

.divider:after {
  display: block;
  content: "";
  border-bottom: 2px solid $black5;
  flex: 1;
  margin-left: 20px;
  margin-bottom: 12px;
}

.heading {
  padding: 1em 0;
  margin: 0;
  margin-bottom: -0.9em;
  line-height: 1.5em;
  outline: none;
}

.list {
  margin: 0;
  padding-left: 40px;
  outline: none;
}

.listItem {
  padding: 5.5px 0 5.5px 3px;
  line-height: 1.6em;
}

.dosAndDontsItem {
  display: flex;
  padding: 0 10px;
}

.green {
  background: $green;
}

.red {
  background: $red;
}

.do::before {
  content: "Do: ";
  font-weight: 600;
}

.dont::before {
  content: "Don't: ";
  font-weight: 600;
}

.dosAndDontsDot {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  width: 10px;
  height: 10px;
  margin: 15px 15px 10px 0;
  border-radius: 50%;
}

.dosAndDontsText {
  flex-grow: 1;
  padding: 10px 0;
}

.important {
  padding: 15px;
  border: 2px solid $red;
  border-radius: 8px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
}

.importantHeader {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -15px;
  left: 0;
  right: 0;
}

.importantHeaderContent {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  color: $red;
  font-style: italic;
  font-weight: 600;
}

.importantContent {
  outline: none;
}

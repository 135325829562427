.root {
  width: $mainContentWidth;
  margin: 50px auto 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px 0 20px;
  max-width: 500px;
}

.basics {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.basicsName {
  width: 400px;
  margin-right: 25px;
}

.profilePicture {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  transition: all $transitionTimeBase;
}
.profilePictureSourcePending {
  opacity: 0;
}

.profilePictureSource {
  box-shadow: none;
}

.profilePictureInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.profilePictureButton {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80px;
  background: rgba(255, 255, 255, 0.8);
  color: $purple;
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  transition: all $transitionTimeBase;
  height: 30px;
  pointer-events: none;
}
:global(.theme-influenceM) .profilePictureButton {
  color: $influenceMYellow;
}
.profilePicture:hover .profilePictureButton {
  height: 80px;
  cursor: pointer;
}
.profilePicture:hover .profilePictureButtonDisabled,
.profilePictureButtonDisabled {
  height: 0;
}

.profilePictureLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profielImageLoadingDot {
  background-color: $purple;
}
:global(.theme-influenceM) .profielImageLoadingDot {
  background-color: $influenceMYellow;
}

.selectRegion {
  position: relative;
  padding: 17px 0;
  margin-top: 16px;
  margin-left: -22px;
}

.selectRegion > div {
  right: initial;
  margin-top: 3px;
}

.instagramUsername {
  width: 320px;
  margin-top: 32px;
  margin-bottom: 20px;
}

.themePicker {
  margin-top: 32px;
  margin-left: -16px;
}

.footer {
  margin: 64px 0;
}

.logOut {
  width: 400px;
}

.dots {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 1px solid $white;
  background-color: rgba(235, 235, 235, 0.5);
  margin: 10px 5px;
  box-shadow: 0 2px 5px $black;
}

.click {
  cursor: pointer;
}

.active {
  background-color: $white;
}

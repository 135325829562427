.rootNoNew {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emojiWrapper {
  padding-top: 20px;
}

.emoji {
  font-size: 50px;
}

.header {
  display: flex;
}

.left {
  flex: 2;
}

.right {
  flex: 1;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bold {
  font-weight: 700;
  font-size: 17px;
}

.mineWrapper {
  margin-top: 40px;
}

.regionWrapper {
  position: relative;
}

.checked {
  background-color: $black60;
  color: $white;
  border: 1px solid $black60;
  border-radius: 0;
  height: 32px;
}

.appHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 36px;
}

.filterRoot {
  margin-top: 40px;
}

.filters {
  display: contents;
}

.regionFilter {
  margin-left: 20px;
}

.campaignSwitchFilter {
  margin-left: 10px;
}

.hiddenLabel {
  display: none;
}

.container {
  display: flex;
  justify-content: space-between;
  color: $purple;
  padding: 30px 70px 30px 70px;
}
:global(.theme-influenceM) .container {
  color: $influenceMYellow;
}


.a a {
  color: $purple;
}
:global(.theme-influenceM) .a a {
  color: $influenceMYellow;
}

.links li {
  list-style: none;
  margin-right: 55px;
  float: left;
}

.links li a {
  color: $purple;
  white-space: nowrap;
}
:global(.theme-influenceM) .links li a {
  color: $influenceMYellow;
}

.links li a:global(.active),
.forceActive {
  font-weight: $bold;
  border-bottom: 2px solid $purple;
  padding-bottom: 5px;
}
:global(.theme-influenceM) .links li a:global(.active),
:global(.theme-influenceM) .forceActive {
  border-bottom-color: $influenceMYellow;
}

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.periods {
  position: relative;
  margin-top: -20px;
}

.period {
  display: flex;
  margin-top: -35px;
}

.fragment {
  display: flex;
  min-width: 0;
}

.fragmentInnards {
  display: flex;
  flex: 1;
  height: 35px;
  background-color: $purpleLight;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;

  font-size: 13px;
  color: $purple;
  font-weight: 600;
}

.nowContainer {
  display: flex;
}

.nowProportion {
  display: flex;
  position: relative;
}

.now {
  width: 4px;
  height: 45px;
  position: absolute;
  right: -2px;
  top: -40px;
  background-color: $purple;
  opacity: 0.5;
  border-radius: 2px;
}

.calendar {
  display: flex;
  position: relative;
}

.calendar::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;

  background-image: linear-gradient(white, rgba(255, 255, 255, 0));
}

.calendar::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;

  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
}

.day {
  flex: 1;
  border-right: 1px solid $black5;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 20px;
  font-size: 12px;
  font-weight: 500;
  color: $black50;
}

.day:last-child {
  border-right: 0;
}

.weekend {
  background-color: rgb(247, 247, 247);
}

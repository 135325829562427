.progressBar {
  height: 3px;
  width: 100%;
  background-color: $purpleLight;
  position: relative;
  margin-top: 6px;
  border-radius: 3px;
}

.bar {
  background-color: $purple;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

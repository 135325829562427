.root {
  margin-top: 60px;
  vertical-align: top;
  display: flex;
}

.influencerBox {
  margin-bottom: 20px;
  padding: 5px;
  padding-top: 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.userProfilePicture {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

.influencerInfo {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.influencerUsername {
  font-weight: $bold;
  font-size: 20px;
  line-height: 1;
  display: block;
}

.influencerFollowers {
  font-size: 14px;
}

.postDate {
  color: $black30;
  font-size: 16px;
  margin-left: auto;
  align-self: baseline;
}

.gigImageWrapper {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black5;
  overflow: hidden;
}

.postWrapper {
  height: 350px;
  width: 350px;
  margin: 10px 10px;
}

.storyWrapper {
  height: 585px;
  width: 330px;
  margin: 10px 20px;
}

.galleryWrapper {
  flex-direction: column;
}

.galleryImageWrapper {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black5;
  overflow: hidden;
  width: 235px;
  height: 235px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  width: 1110px;
  margin-left: -15px;
}

.gigImage {
  width: 100%;
  height: auto;
  margin: 0;
}

.gigResults {
  display: flex;
  justify-content: space-around;
  font-weight: $light;
  font-size: 14px;
}

.caption {
  font-size: 16px;
  margin-top: 10px;
}

.details {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.stats {
  margin-top: 28px;
  display: inline-block;
}
.performance {
  margin-right: 28px;
  width: 100%;
}
.stats > div {
  display: inline-block;
}

@media print {
  .root {
    page-break-inside: avoid;
  }

  .caption {
    font-size: 12pt;
  }

  .influencerUsername {
    font-size: 18pt;
    margin-left: 9pt;
  }
}
div.heading {
  display: block;
  font-weight: $bold;
  margin-bottom: 10px;
}
.engagement_delta {
  color: $gold70;
  font-size: 11px;
  margin-left: 10px;
}

.main {
  margin-top: 200px;
  margin: auto;
}

.component404 {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  right: 0;
  left: 0;
}

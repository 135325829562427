.root {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.containerMargin {
  margin-top: 48px;
}

.price,
.assetCount,
.engagement,
.impression,
.followerReach {
  width: 380px;
}

.listPrice {
  width: 380px;
}

.withPremiumPrice {
  margin-top: 12px;
}

.item {
  flex: 1;
  margin: 0 20px;
}

.price {
  margin-left: 20px;
  margin-right: 20px;
}

.subheading {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
}

.sidenote {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.description {
  color: $black50;
  font-size: 14px;
  margin-bottom: 20px;
}

.choices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -10px -15px -12px;
  flex-wrap: wrap;
}

.radioButton {
  flex: 1 0 50%;
  margin-bottom: 15px;
}

.radioButton50 {
  flex: 1 0 50%;
  margin-bottom: 15px;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 -20px 40px -20px;
}

.left {
  flex: 1.3;
  max-width: 600px;
}

.right {
  flex: 1;
}

.checkbox {
  margin-top: 20px;
  max-width: 500px;
}

.checkboxDescription {
  color: $black50;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 36px;
}

.checkboxDescription p {
  margin-bottom: 1em;
}

.total {
  font-weight: 700;
}

.label {
  cursor: pointer;
  user-select: none;
  margin-bottom: 15px;
}

.otherCheckbox {
  max-width: 300px;
  margin-top: 10px;
  margin-right: 52px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabled * {
  cursor: not-allowed;
}

.salesforce {
  min-height: 185px;
}

.saveBtn {
  width: 80px;
  height: 40px;
  border-radius: 0 !important;
  padding: 12px 16px !important;
  color: white !important;
  background-color: black !important;
  font-size: 14px;
  font-weight: 600;
  font-variant: none;
  text-transform: none;
}

.archiveBtn {
  width: 94px;
  height: 40px;
  border-radius: 0 !important;
  padding: 12px 16px !important;
  color: white !important;
  background-color: black !important;
  font-size: 14px;
  font-weight: 600;
}

.saveBtn:hover {
  background-color: black !important;
}

.wrapper {
  font-size: 14px;
  margin-top: 20px;
}

.title {
  color: $purple;
  font-weight: $roman;
}
:global(.theme-influenceM) .title {
  color: $influenceMYellow;
}

.list {
  color: color($purple a(70%));
  font-weight: $light;
}
:global(.theme-influenceM) .list {
  color: color($influenceMYellow a(70%));
}

.modalBg {
  position: fixed;
  top: 90px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(255,255,255,0.97);
  display: flex;
  justify-content: center;
  overflow: scroll;
  padding: 60px 0;
  backdrop-filter: blur(5px);
}

.modalItem {
  width: 500px;
  position: relative;
  background-color: rgba(255,255,255,0.8);
  border-radius: 5px;
}

.wide {
  width: 900px;
}

.modalBio {
  font-size: 12px;
  color: $black70;
  line-height: 22px;
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: -30px;
}

.modalCloseButton {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $purpleLight;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.modalCloseButton:hover {
  color: white;
  background-color: $gold70;
  transition: all 0.2s;
}

.modalProceedButton {
  position: absolute;
  top: 20px;
  left: 0px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0 30px;
  line-height: 24px;
  border-radius: 12px;
  color: $gold70;
  background-color: $gold5;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.modalProceedButton:hover {
  color: white;
  background-color: $gold70;
  transition: all 0.2s;
}

.modalCloseButton:hover .modalCloseButtonIcon {
  color: white;
}

.modalCloseButtonIcon {
  color: $purple;
  font-size: 19px;
}

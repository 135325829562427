.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: $purple;
  z-index: 1000;
  background: $purple -webkit-gradient(left top, right top, color-stop(0%, rgba(155, 81, 224, 1)), color-stop(51%, white), color-stop(100%, rgba(155, 81, 224, 1)));
  background: $purple linear-gradient(to right, rgba(155, 81, 224, 1) 0%, white 51%, rgba(155, 81, 224, 1) 100%);
  animation: loader 2s linear infinite;
  will-change: opacity;
  transition: opacity 0.3s;
  opacity: 1;
  background-size: 200%;
}

.hidden {
  opacity: 0;
}

@keyframes loader {
  from {
    background-position: 200% 0%;
  }
  to {
    background-position: 0% 0%;
  }
}

.root {
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1em;
}

.fullHeight {
  min-height: 100%;
}

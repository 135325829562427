.root {
  display: flex;
  flex-direction: column;
  flex: 100%;
}

.center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
  margin: 75px auto;
}

.loader {
  margin-left: 10px;
}

.root {
  -webkit-appearance: none;
  background: padding-box $white;
  border-radius: 4px;
  border: 2px solid $black20;
  box-shadow: none;
  color: $black;
  font-size: 15px;
  height: 100%;
  margin: 0;
  min-height: 42px ;
  outline: 0;
  padding: 9px 40px 9px 8px;
  width: 100%;
}

.root {
  display: flex;
  align-items: flex-start;
  margin: 10px 10px 14px 12px;
}

.photo {
  margin-top: 2px;
  flex: 0 0 30px;
}

.meta {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info {
  margin-top: 4px;
  margin-left: 8px;
  flex: 1;
}

.campaign {
  font-size: 12px;
  font-weight: $bold;
  line-height: 14px;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.advertiser {
  font-size: 10px;
}

.admin {
  line-height: 0;
}

.paidAmount {
  font-size: 26px;
  font-weight: $light;
  color: $black40;
}

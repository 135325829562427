.table {
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid $black10;
  margin-top: 40px;
  border-collapse: collapse;
  margin-bottom: 40px;
}
.dragging {
  pointer-events: none;
}

.tableRow {
  border-top: 2px solid transparent;
  box-sizing: border-box;
}

.headingRow {
  min-height: 50px;
  border-bottom: 1px solid $black10;
  border-collapse: separate;
}

.hovering {
  border-top: 2px solid $purple;
}

.footerRow {
  height: 50px;
}

.headingItem {
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid $black10;
  padding: 15px 15px;
}

.headingItem:first-child {
  text-align: left;
}

.tbody {
  border: 1px solid black;
}

.row:nth-child(2n) {
  background-color: #fcfcfc;
}

.rowClickable {
  min-height: 50px;
  cursor: pointer;
}

.rowClickable:nth-child(2n) {
  background-color: #fcfcfc;
}

.rowClickable:hover {
  background-color: #f4f4f4;
}

.item {
  text-align: center;
  padding: 5px 15px;
  border: 0;
}

.item:first-child {
  text-align: left;
}

.emptyMessage {
  text-align: center;
  padding: 20px 0;
  height: 100px;
  color: $black30;
}

.footer .item {
  padding: 15px 15px;
  border-top: 1px solid $black10;
}

.hyphen {
  color: $black20;
}

.container {
  margin-left: auto;
  margin-right: 15px;
  display: flex;
  cursor: pointer;
  user-select: none;
}

.moreFilters {
  padding-right: 15px;
  font-size: 14px;
  color: $black20;
  transition: color 0.3s;
}
.moreFilters.filtersActive {
  color: $purple;
}

.button {
  width: 20px;
  height: 20px;
  position: relative;
}
.button .chevron::before {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background-color: $black20;
  position: absolute;
  left: 0;
  transform: rotate(30deg) scaleX(1.2);
  transition: all 0.3s;
}
.button .chevron::after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background-color: $black20;
  position: absolute;
  right: 0;
  transform: rotate(-30deg) scaleX(1.2);
  transition: all 0.3s;
}

.button {
  padding-top: 10px;
}

.button.clicked .chevron::before {
  transform: rotate(-30deg) scaleX(1.2);
}

.button.clicked .chevron::after {
  transform: rotate(30deg) scaleX(1.2);
}

.button.filtersActive .chevron::before, .button.filtersActive .chevron::after {
  background-color: $purple;
}

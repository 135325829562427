.extraRow {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px dotted $black10;
}

.section {
  font-size: 12px;
  padding: 0 15px;
}

.header {
  font-weight: 600;
}

.statIcon {
  font-size: 12px;
}

.statsRows {
  display: flex;
  flex-direction: column
}

.tableRow > td {
  padding: 0 4px 0 0;
}

.leftCell {
  border-right: 1px dotted $black50;
}

.leftColumn {
  margin-right: 5px;
}

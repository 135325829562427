.root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  margin-bottom: 10px;
  flex:1;
}

.label {
  display: block;
  width: 49px;
  height: 32px;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px #E4E4E4;
  transition: all 200ms;
  cursor: pointer;
}

.toggle {
  text-align: left;
}

.input {
  display: none;
}

.icon {
  height: 28px;
  width: 28px;
  background: #FFF;
  display: inline-block;
  border-radius: 60px;
  margin-top: 2px;
  margin-left: 2px;
  transition: transform 300ms;
  box-shadow: 0 0 0 1px #E4E4E4, 0 3px 2px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.input:checked + .label > .icon {
  transform: translate3d(17px, 0, 0);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
}

.label:active {
  box-shadow: inset 0 0 0 20px #E4E4E4;
}

.input:checked + .label:active > .icon {
  transform: translate3d(13px, 0, 0);
}

.input:checked + .label {
  box-shadow: inset 0 0 0 20px #53D769;
  border: none;
}

.title {
  font-weight: $bold;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  display: block;
}

.description {
  font-weight: $light;
  font-size: 9px;
  margin-right: 10px;
  display: block;
  cursor: pointer;
}

.root {
  margin-bottom: 10px;
}

.root > div {
  width: 100%;
}

.label {
  font-size: 14px;
  color: $black60;
  cursor: pointer;
}

.label.big {
  color: black;
  font-size: 16px;
}

.datePicker {
  font-size: 18px;
  line-height: 3em;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 2px solid $black10;
  margin-bottom: 8px;
}

.datePicker.small {
  font-size: 15px !important;
  padding: 5px 0 !important;
  border-bottom: 1px solid #E6E7E8 !important;
  color: $black90;
}

:global(.react-datepicker__input-container) {
  width: 100%;
}

:global(.react-datepicker__close-icon::after) {
  background-color: transparent !important;
  color: $black50 !important;
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
}

.error {
  margin: 5px 0 10px 0;
  font-size: 13px;
  color: $red;
}

.description {
  color: $black40;
  font-size: 12px;
  font-weight: 500;
}

.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.headerWrapper {
  margin-bottom: 20px;
}

.header {
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  color: $black;
}

.subHeader {
  color: $black40;
  font-weight: 600;
}

.root {
  width: 450px;
}

.forgotLink {
  color: $purple;
}
:global(.theme-influenceM) .forgotLink {
  color: $influenceMYellow;
}

.topRow {
  min-height: 150px;
}

.advertiserHeader {
  display: inline-block;
  width: 60%;
  vertical-align: top;
}

.advertiserLogo {
  display: inline-block;
  vertical-align: middle;
}

.advertiserName {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: $bold;
  vertical-align: middle;
}

.brief {
  font-size: 14px;
  margin-bottom: 90px;
  white-space: pre-line;
  padding-right: 50px;
}

.progressTopWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.header {
  font-weight: $bold;
}

.progressBottomWrapper {
  display: flex;
  position: relative;
  font-weight: $bold;
  font-size: 14px;
  color: $black60;
  margin-top: 5px;
}

.reservationCount {
  position: absolute;
  top: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.campaignDatesWrapper {
  margin-bottom: 20px;
  display: flex;
}

.campaignStatsWrapper {
  display: flex;
}

.campaignBudgetActionsWrapper {
  margin-top: 20px;
}

.campaignBudgetRow {
  margin-bottom: 20px;
}

.campaignReportLinkTitle {
  font-weight: $bold;
  font-size: 16px;
}

.remainingFundsColumn {
  flex: 1 1 40px;
}

.infoValue {
  display: flex;
}

.costToReach,
.estBudgetReach {
  font-size: 12px;
  color: $black30;
  line-height: 1.2;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.fade {
  opacity: 0.6;
}

.reportSection {
  margin-bottom: 40px;
}

.sectionHeading {
  font-size: 20px;
  font-weight: $bold;
  margin-bottom: 30px;
  text-transform: lowercase;
  font-variant: small-caps;
}

.leftColumn {
  display: inline-block;
  width: 70%;
  vertical-align: top;
}

.rightColumn {
  display: inline-block;
  width: 30%;
  vertical-align: top;
}

.brief p {
  margin-bottom: 1.5em;
  page-break-inside: avoid;
}

.facebookButton {
  width: 367px;
  padding: 10px 0 12px;
  background-color: $facebookBlue;
  border: 0;
}

.facebookButton:hover {
  background-color: color($facebookBlue lightness(-5%));
}

.csvLink {
  color: $purple;
  margin-top: 10px;
  margin-bottom: 30px;
  display: inline-block;
}
:global(.theme-influenceM) .csvLink {
  color: $influenceMYellow;
}

.downloadButton {
  display: block;
}

.definitionRow {
  display: flex;
  margin-bottom: 22px;
}

@media print {
  .downloadButton,
  .facebookButton,
  .csvLink {
    display: none;
  }

  .pageBreak {
    page-break-before: always;
  }
}

.root {
  margin: 20px 0;
  width: 640px;
}

.event {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.profilePicture {
  flex: 0 0 40px;
  margin-right: 13px;
}

.input {
  width: 100%;
  margin-top: 20px;
}

.groupTitle {
  color: $black50;
  padding: 10px 0;
  text-transform: lowercase;
  font-variant: small-caps;
}

.eventCount {
  text-align: left;
  font-size: 14px;
  text-transform: lowercase;
  font-variant: small-caps;
  color: #a7a9ac;
  font-weight: 500;
  margin: 20px -5px;
}

.filterInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.filterGroup {
  display: flex;
  align-items: flex-end;
  margin-right: -10px;
}

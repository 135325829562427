.root {
  display: flex;
  flex-direction: column;
}

.rootWrapper {
  padding: 12px;
  margin: 10px 0;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
  width: 400px;
}

.commentWrapper {
  composes: rootWrapper;
  border: 0.5px solid $purpleLight;
  border-radius: 4px 4px 0px 4px;
  background: $gold5;
}

.brandCommentWrapper {
  composes: rootWrapper;

  border: 0.5px solid $black10;
  border-radius: 4px 4px 4px 0;
  background: $black5;
  text-align: right;
  align-self: flex-end;
}

.user {
  color: $purple;
  font-weight: 400;
  margin-bottom: 2px;
}

.noComments {
  color: $black40;
  text-align: center;
}

.date {
  color: $black20;
  font-size: 12px;
}

.created {
  composes: date;
  text-align: right;
}

.brandCreated {
  composes: date;
  text-align: left;
}

.input {
  margin: 0 20px;
}

.notSeen {
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-timing-function: ease-in;
  will-change: background-color;
}

@keyframes colorBreathBrand
{
  0%  {background-color: $black5;}
  50% {background-color: color($black20 a(80%));}
  100% {background-color: $black10;}
}



.notSeenBrand {
  composes: notSeen;
  animation-name: colorBreathBrand;
}

@keyframes colorBreath
{
  0%  {background-color: $gold5;}
  50% {background-color: color($gold70 a(50%));}
  100% {background-color: $purpleLight;}
}

.notSeenTakumi {
  composes: notSeen;
  animation-name: colorBreath;
}

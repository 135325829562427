.root {
  margin-top: 20px;
  margin-bottom: 50px;
}

.heading {
  color: $black70;
  font-size: 20px;
  font-weight: 600;
}

.sectionHeading {
  color: $black70;
  font-size: 30px;
  font-weight: 600;
  margin: 10px 0 20px;
}

.expired {
  background-color: $red;
  color: white;
  padding: 5px 20px;
  margin-left: 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
}

.created {
  color: $black40;
  font-size: 14px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background-color: rgba(248,248,248);
  padding: 10px;
  border-radius: 5px;
}

.insightContainer {
  display: flex;
}

.insightValues {
  margin-left: 10px;
}

.insightMedia {
  padding-top: 20px;
}

.description {
  font-size: 14px;
  margin-bottom: 20px;
}

.fadedDescription {
  color: $black50;
  font-size: 14px;
  margin-bottom: 20px;
}

.progressBar {
  height: 3px;
  width: 100%;
  position: relative;
  margin-top: 6px;
  border-radius: 3px;
}

.bar {
  background-color: $purple;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hasErrors {
  margin-top: 10px;
  font-size: 22px;
  color: red;
}

.errorReason {
  font-weight: bold;
}

.root {
  width: 260px;
  padding: 10px;
  padding-bottom: 0;
}

.title {
  color: $white;
  line-height: 1.2em;
  font-size: 14px;
  font-weight: 500;
}

.form {
  margin-top: 10px;
  display: flex;
}

.input {
  font-size: 14px;
  background: $black;
  color: $white;
  border-color: $black90;
  border-bottom-width: 1px;
  margin-bottom: 0;
}
.input:focus {
  border-color: $purple;
}
:global(.theme-influenceM) .input:focus {
  border-color: $influenceMYellow;
}

.button {
  margin-left: 20px;
  margin-top: 8px;
}

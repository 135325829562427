$rowSpacing: $gridRowSpacing;
$gutter: $gridGutter;

.row {
  display: flex;
  align-items: flex-start;
  margin-left: -$gutter;
  margin-right: -$gutter;
  margin-top: $rowSpacing;
  margin-bottom: $rowSpacing;
}

.column {
  flex: 1;
  padding-left: $gutter;
  padding-right: $gutter;
}

.gutter {
  margin-left: calc($gutter * -2px);
  margin-right: calc($gutter * -2px);
}

.gutter > .column {
  padding-left: calc($gutter * 2px);
  padding-right: calc($gutter * 2px);
}

.right {
  text-align: right;
}

.noMargin {
  margin: 0;
}

.noPadding > .column {
  padding: 0;
}

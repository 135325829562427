.topBar {
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  align-items: center;
}

.selectRegion {
  position: relative;
  width: 230px;
  display: block;
  height: 35px;
  margin-top: 16px;
  margin-left: 16px;
}

.count {
  flex: 1;
  text-align: left;
  font-size: 14px;
  text-transform: lowercase;
  font-variant: small-caps;
  color: $black50;
  margin-bottom: 30px;
  font-weight: 500;
}

.combinedButtonLeft {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 125px;
  padding-left: 0;
  padding-right: 0;
}

.combinedButtonRight {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  opacity: 0.8;
  width: 125px;
  padding-left: 0;
  padding-right: 0;
}

.root {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  padding: 0 10px;
  position: relative;
}

.border {
  border: 1px solid $black10;
  border-radius: 4px;
  line-height: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.select {
  appearance: none;
  bottom: 0;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 100%;
  left: 10px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.current {
  color: $black80;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  min-height: 30px;
  overflow: hidden;
  padding: 10px 30px 6px 12px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.current:after {
  content: '';
  display: inline-block;
  border: 2px solid #C7C7C7;
  border-top: none;
  border-left: none;
  height: 10px;
  margin-left: 16px;
  transform: rotate(45deg);
  width: 10px;
  position: absolute;
  right: 14px;
  top: calc(50% - 8px);
}

.placeholder {
  color: $black30;
}

.error {
  border: 3px solid $red;
  border-width: 0 10px 0 0;
  transition: border 100ms ease 2s;
}

.disabled {
  opacity: .5;
}

.disabledSelect {
  cursor: auto;
}

.block {
  min-width: 100%;
}

.withDescription {
  margin: 15px 0 0 -2px;
}

.big {
  font-size: 18px;
  color: $black70;
  padding: 10px 30px 6px 0;
}

.description {
  margin-top: 6px;
  color: $black40;
  font-size: 12px;
  font-weight: 500;
}


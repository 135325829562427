.button {
  width: 15px;
  height: 15px;
  padding: 6px;
  background-color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  /*box-shadow: $boxShadowBase;*/
}

.buttonActive {
  width: 15px;
  height: 15px;
  padding: 6px;
  background-color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  /*box-shadow: 0 5px 10px rgba(37, 37, 37, 0.1);*/
  cursor: help;
}

.button:hover {
  opacity: 1;
  cursor: pointer;
}

.icon {
  color: $black50;
  display: block;
  flex: 1;
  padding-left: 1px;
}

.iconActive {
  color: $green;
  display: block;
  flex: 1;
  padding-left: 1px;
}

.container {
  visibility: hidden;
  transition: opacity 0.2s;
  opacity: 0;
}

.container.reviewed {
  visibility: visible;
  opacity: 1;
}

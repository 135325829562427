.resetButton {
  position: absolute;
  bottom: 30px;
  right: 50px;
  font-size: 14px;
  color: $black20;
  cursor: pointer;
}

.resetButton:hover {
  color: $black30;
}

.root {
  margin-bottom: 50px;
}

.link {
  color: $purple;
  text-decoration: underline;
  display: cursor;
}

.remainingOffers {
  background-color: #fcfcfc;
}

.row {
  min-height: 50px;
  background-color: white;
}

.row:nth-child(2n) {
  background-color: #fcfcfc;
}

.heading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.instructions {
  margin-bottom: 20px;
}

.instructions > p + p {
  margin-top: 12px;
}

.comments {
  color: $purple;
  font-variant: small-caps;
  text-transform: lowercase;
}

.countBubbleWrapper {
  position: relative;
  display: inline;
}

.countBubble {
  display: block;
  text-align: center;
  color: white;
  position: absolute;
  cursor: auto;
  width: 20px;
  height: 20px;
  font-size: 11px;
  line-height: 15px;
  font-weight: 900;
  border-radius: 10px;
  border: 2px solid white;
}

.countBubbleAll {
  composes: countBubble;
  background: $purple;
  top: -10px;
  right: -14px;
}

.countBubbleUnseen {
  composes: countBubble;
  background: $red;
  right: -20px;
  top: -15px;
}

.button {
  border-radius: 9999px;
  cursor: pointer;
  font-size: 16px;
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: 500;
  line-height: 1;
  transition: background 200ms;
  white-space: nowrap;
  border: none;
  background: transparent;
  color: $purple;
  margin: 0 10px;
  padding: 0;
}
:global(.theme-influenceM) .button {
  color: $influenceMYellow;
}

.button:hover {
  background-color: transparent;
  color: $purple;
  text-decoration: underline;
}
:global(.theme-influenceM) .button:hover {
  color: $influenceMYellow;
}

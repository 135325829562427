.root {
  border: 1px dashed $purple;
  margin: 0 0 0 -10px;
  padding: 10px;
}

.header {
  background: white;
  color: $purple;
  margin-left: 10px;
  margin-top: -22px;
  padding-left: 5px;
  width: 100px;
}

.root {
  min-height: 100px;
}

.container {
  position: relative;
}

.textarea {
  appearance: none;
  border: 2px solid $black10;
  border-radius: 2px;
  display: block;
  font-size: inherit;
  min-height: 12em;
  line-height: inherit;
  outline: none;
  padding: 12px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  font-family: $fontFamily;
}

.root button {
  font-weight: $bold;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  line-height: 150%;
  padding: 10px 20px;
  transition: background 200ms;
  margin: 10px 0;
  background: transparent;
  color: $purple;
  margin: 0;
  padding: 0;
}

:global(.theme-influenceM) .root button {
  color: $influenceMYellow;
}

.root button:hover {
  background-color: transparent;
  color: $purple;
  text-decoration: underline;
}

:global(.theme-influenceM) .root button:hover {
  color: $influenceMYellow;
}

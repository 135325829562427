.root {
  margin: 0 auto;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: color($black a(75%));
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: calc(600px + 335px);
  background: $white;
  display: flex;
  border: 1px solid $black90;
}

.gigData {
  padding: 15px 20px 12px 20px;
  width: 335px;
  height: 700px;
  display: flex;
  flex-direction: column;
}

.topSection {
  flex: 0 0 300px;
}

.influencerUser {
  flex: 1 0 20px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 26px;
  height: 50px;
  box-sizing: content-box;
  position: relative;
}

.influencerUserMeta {
  display: flex;
  justify-content: space-between;
}

.influencerUserInfo {
  margin-left: 12px;
  max-width: 240px;
}

.influencerUserPhoto {
  flex: 0 0 50px;
  margin-left: -4px;
}

.influencerUserName {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.influencerUserFollowers {
  font-size: 12px;
  color: $black40;
  padding: .2rem 0 0 .2rem;
}

.campaign {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  margin-top: 20px;
  max-width: 295px;
}

.campaignBrandLogo {
  flex: 0 0 44px;
}

.campaignInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 15px;
  padding-right: 8px;
  overflow: hidden;
}

.campaignName {
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.campaignBrandName {
  font-size: 12px;
  font-weight: 500;
  color: $black40;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.gigBusStop {
  padding: 26px 0 13px;
  box-sizing: content-box;
}

.bottomSection {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
  border-top: 1px solid #efefef;
  overflow: auto;
}

.gigMediaInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $black5;
}

.arrow {
  position: absolute;
  top: 50%;
  margin-top: -34px;
  cursor: pointer;
}

.leftArrow {
  composes: arrow;
  left: calc(50% - 467px - 60px);
}

.rightArrow {
  composes: arrow;
  right: calc(50% - 467px - 60px);
}

.disable {
  display: none;
}

.pillContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}

.header {
  display: flex;
  flex-direction: row;
}

.menu {
  text-align: right;
  width: 20px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 26px;
}

.menu span {
  background: $black80;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  width:  4px;
}

.link {
  color: $purple;
  font-size: 12px;
  font-weight: $bold;
  cursor: pointer;
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: 12px;
}

.wrapper {
  margin-bottom: 16px;
}

.label {
  padding-left: 20px;
  font-size: 14px;
  color: $black60;
  cursor: pointer;
}

.label.big {
  color: black;
  font-size: 16px;
}

.description {
  font-size: 13px;
  color: $black60;
  cursor: pointer;
}

.radioButton {
  -webkit-appearance: none;

  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid $black20;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 16px;

  transition: background-color 0.3s;
}

.radioButton:after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  transform: scale(0);
  background-color: $purple;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioButton:checked:after {
  transform: scale(1);
}

.disabled.radioButton:after {
  background-color: $purple !important;
}

.container:hover .radioButton {
  background-color: $purpleLight;
}

.container:hover .radioButton.disabled {
  background-color: inherit !important;
}

.disabled {
  color: $black10 !important;
  cursor: unset;
}

.disabledLabel {
  color: $black20 !important;
  cursor: unset;
}

.disabled:hover {
  background-color: inherit !important;
}

.reportTable td, .reportTable th {
  padding: 10px 15px;
  text-align: center;
}

.reportTable tr:nth-child(even){
  background-color: $black5;
}

.link {
  cursor: pointer;
}

.link :hover {
  font-weight: bold;
  text-decoration: underline;
}

.link .disabled {
  cursor: default;
  font-weight: normal;
  text-decoration: solid;
}

.wrapper {
  margin-bottom: 15px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  padding-right: 36px;
  height: 16px;
  width: 16px;
}

.checkbox {
  height: 16px;
  position: absolute;
  width: 16px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid $black20;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  pointer-events: none;
}

.checkbox.checked {
  background-color: $purple;
  border: 0;
}

.checkbox.disabled {
  background-color: $black20;
}

.container:hover .checkboxContainer.checkbox:not(.checked) {
  background-color: rgb(247, 247, 247);
}

.label {
  font-size: 14px;
  color: $black60;
  cursor: pointer;
  user-select: none;
}

.label.big {
  color: $black;
  font-size: 16px;
}

.description {
  margin-left: 36px;
  font-size: 13px;
  color: $black60;
  cursor: pointer;
}

.hiddenButton {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 16px;
  width: 16px;
}

.light {
  color: $black70 !important;
}

.label {
  display: flex;
  color: $black40;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  align-items: baseline;
  margin-top: -10px;
}

.status {
  flex-shrink: 0;
  font-weight: 600;
  margin-left: $gridGutter;
  opacity: 0;
  transition: all $transitionTimeBase;
}
.statusSaved {
  color: $green;
}
.statusFailed {
  color: $red;
}
.statusVisible {
  opacity: 1;
}

.controlWrapper {
  height: 64px;
  max-height: 64px;
  position: relative;
}

.control {
  position: absolute;
  bottom: 0;
  width: 100%;
}

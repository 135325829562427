.root {
  font-size: 12px;
}

.busStop {
  position: relative;
  padding-bottom: 17px;
}

.lastBusStop {
  position: relative;
  margin-bottom: 0;
}

.gigState {
  display: flex;
  justify-content: space-between;
  margin-left: 14px;
}

.dot {
  position: absolute;
  top: 3px;
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: $black;
  z-index: 1;
}

.dotGray {
  position: absolute;
  top: 2px;
  left: -1px;
  background-color: $white;
  height: 10px;
  width: 10px;
  border: 2px solid $black40;
  border-radius: 50px;
  z-index: 1;
}

.line {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: $black;
  left: 3px;
  top: 5px;
}

.lineGray {
  composes: line;
  background-color: $black40;
}

.rightAlignText {
  position: relative;
  z-index: 2;
  text-align: right;
}

.fadeOut {
  color: $black60;
}

.reason {
  max-width: 200px;
  max-height: 50px;
  overflow-y: scroll;
}

.openReport {
  color: $purple;
}

.profilePictureWrapper {
  position: relative;
}

.profilePicture {
  margin-bottom: 5px;
  width: 80px;
  height: 80px;
}

.info {
  margin-left: 10px;
}

.fullName {
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
}

.email {
  font-size: 12px;
  color: $black40;
  text-transform: lowercase;
  white-space: nowrap;
}

.reporter {
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
}

.content {
  display: flex;
  flex-direction: row;
}

.left {
  flex: 3 0 auto;
  margin-right: 60px;
}

.right {
  flex: 2 0 auto;
}

.description {
  color: $black40;
  display: flex;
  width: 700px;
}

.price {
  width: 320px;
  margin-bottom: 40px;
}

.rightDescription {
  color: $black40;
  display: flex;
  width: 240px;
}

.formSection {
  margin-bottom: 40px;
}

.rowHeading {
  font-weight: 700;
  margin-bottom: 20px;
}

.radioButton {
  width: 12.5rem;
}

.checkBox {
  width: 260px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.saveButton {
  margin-right: 10px;
}

.choices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 42rem;
  justify-content: space-between;
  margin-left: -12px;
  margin-right: -10px;
}

.galleryChoices {
  flex-direction: column;
}

.scheduleWarning {
  color: color($yellow lightness(-20%));
  background-color: color($yellow a(20%));
  padding: 12px;
  border-radius: 6px;
  margin: 12px 0;
}

.briefWarning {
  display: flex;
  width: 700px;
  color: color($red lightness(-30%));
  background-color: color($red a(20%));
  padding: 12px;
  border-radius: 6px;
  margin: 12px 0;
}

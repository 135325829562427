.root {
  display: flex;
  justify-content: flex-start;
  margin-bottom: -30px;
  cursor: pointer;
}

.right {
  justify-content: flex-end;
}

.button {
  background-color: $purpleLight;
  color: $purple;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 3px;
}

.categories {
  margin-top: 25px;
}

.categoryName {
  font-size: 14px;
  font-weight: 500;
  color: $black70;
  margin-bottom: 8px;
}

.explainer {
  font-size: 14px;
  color: $black30;
  text-align: center;
  margin-top: 20px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tag {
  transition: all 0.2s;
  background-color: $black5;
  color: $black50;
  display: inline-block;
  padding: 5px 25px;
  border-radius: 3px;
  margin: 0 5px 5px 0;
  font-size: 14px;
  font-weight: 500;
}

.activeTag {
  background-color: $purpleLight;
  color: $purple;
}

.columns {
  display: flex;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 10px;
}

.column {
  flex: 1;
  margin-right: 5px;
  margin-left: 5px;
}

.resetButton {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.extraChildrenOptions {
  margin-left: 35px;
}

.childGenderPicker {
  margin-top: -30px;
}
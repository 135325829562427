.root {
  margin: 20px 0;
  text-align: left;
}

.heading {
  font-weight: $light;
  font-size: 24px;
  margin-bottom: 12px;
}

.section {
  margin-top: 1em;
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: $bold;
}

.item {

}

.complete {
  color: $black50;
  text-decoration: line-through;
}

.complete:before {
  content: '✓';
  color: $green;
  font-size: 24px;
  margin: 0 10px;
  display: inline-block;
  text-decoration: none;
}

.incomplete:before {
  content: '–';
  font-size: 24px;
  margin: 0 13px;
}

.optional {
  color: $black50;
}

.root {
  width: 100%;
  margin-bottom: 40px;
}

.rangeSlider {
  margin: 0px 8px;
}

.labelRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  color: $black50;
  font-weight: 500;
}

.valueLabel {
  font-size: 16px;
  color: $black80;
}

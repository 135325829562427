.root {
  cursor: grab;
}

.overlay {
  background-color: rgba(0,0,0,0.4);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlayText {
  color: white;
  z-index: 100;
}

.imageContainer {
  margin: 2px;
  height: auto;
  max-width: 300px;
  position: relative;
}

.image {
  display: block;
  width: 100%;
  position: relative;
}

.primary {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #FFFFFF;
  padding: 4px 10px;
  border: 1px solid #FFFFFF;
  border-radius: 19px;
  font-size: 16px;
  line-height: 16px;
}

.dragging {
  cursor: grabbing;
}

.remove {
  color: white;
  position: absolute;
  bottom: 0;
  right: 8px;
  cursor: pointer;
}

.dropzone {
  margin: 0 auto;
}

.dropzoneSubtitle {
  display: block;
  font-weight: $light;
}

.root {
  margin-bottom: 30px;
}

.loaderWrapper {
  margin-top: 20px;
}

.noMoreWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 100px;
  font-size: 12px;
  color: $black60;
}

.loadMoreWrapper {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.item {
  flex: 1;
  margin: 0 20px;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  margin: 0 -20px 40px -20px;
}

.subheading {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
}

.description {
  color: $black50;
  font-size: 14px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.saveButton {
  margin-right: 10px;
}

.root {
  display: flex;
  flex-wrap: wrap;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.cell {
  padding: 6px 10px;
  text-align: left;
}

.buttonCell {
  padding: 6px 10px;
  text-align: left;
  float: right;
}
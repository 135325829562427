.root {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.verified {
  color: $green;
  background-color: $white;
  border: 2px solid white;
  border-radius: 50%;
  font-size: 16px;
}

.disabled {
  color: $white;
  background-color: $red;
  border-radius: 50%;
  font-size: 14px;
  padding: 2px;
  font-weight: 700;
  border: 2px solid white;
}

.root {
  margin-top: 70px;
  max-width: $mainContentWidth;
}

.loaderBox {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;
}

.noAdvertisers {
  text-align: center;
  margin-top: 80px;
  font-size: 20px;
}

.noAdvertisersString {
  font-style: italic;
}

.noInfluencers {
  text-align: center;
  margin-top: 80px;
  font-size: 20px;
}

.noInfluencersString {
  font-style: italic;
}

.influencers {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.influencer {
  margin-right: 20px;
  margin-bottom: 20px;
}

.influencer:nth-child(3n + 3) {
  margin-right: 0;
}

.filters {
  display: flex;
}

.filterCheckbox {
  margin: 8px 5px;
}

.influencerCount {
  text-align: left;
  font-size: 14px;
  text-transform: lowercase;
  font-variant: small-caps;
  color: $black40;
  margin-bottom: 30px;
  font-weight: 500;
}

.nonSignedUpInfluencer {
  color: $purple;
  margin-left: 20px;
  font-variant: none;
}

.nonSignedUpInfluencer:hover .searchString {
  font-weight: bold;
}

.followersSlider {
  max-width: 360px;
}

.root {
  display: flex;
  flex-direction: column;
}

.input {
  border: none;
  border-bottom: 2px solid $black10;
  color: #000000;
  font-size: 18px;
  line-height: 3em;
  margin-bottom: 8px;
  transition: all $transitionTimeBase;
}

.input:focus {
  border-bottom-color: $purple;
  outline: 0;
}

.description {
  color: $black40;
  font-size: 12px;
  font-weight: 500;
}

.error,
.error:focus {
  border-bottom: 2px solid $red;
}

.disabled {
  color: $black10 !important;
  border-bottom: 2px solid $black5 !important;
  cursor: disabled;
}
.disabledFix {
  color: $black10 !important;
}

.controlButtons {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.addControl {
  cursor: pointer;
  color: $purple;
}

.addControl:active {
  color: $gold70;
}

.removeControl {
  cursor: pointer;
  color: $gold70;
  margin-right: 10px;
}

input[type="file" i] {
  color: $black70;
  line-height: inherit;
  padding: 1em 0;
  font-size: 12px;
}

.root {
  background-color: $purpleLight;
  color: $purple;
  margin-left: calc(($mainContentWidth - 100vw) / 2);
  margin-right: calc(($mainContentWidth - 100vw) / 2);
  margin-bottom: 50px;
}

.content {
  width: $mainContentWidth;
  position: relative;
  margin: 0 auto;
  padding: 50px 0;
}

.closeButton {
  display: block;
  position: absolute;
  font-size: 40px;
  top: 50px;
  right: 0;
  cursor: pointer;
}

.closeButton:hover {
  opacity: 0.8;
}

.emojis,
.hashtags {
  margin-bottom: 50px;
  max-width: 650px;
  cursor: default;
}

.emoji {
  display: inline-block;
  font-size: 42px;
  margin: 0 10px;
}

.hashtag {
  display: inline-block;
  font-size: 16px;
  margin-right: 20px;
}

.heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 14px;
}

.description {
  margin-top: 0px;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 20px;
  max-width: 650px;
}

.sentiment {
  display: flex;
  flex-direction: row;
}

.percentageGroup {
  margin-right: 50px;
}

.percentage {
  font-size: 48px;
  font-weight: 300;
}

.closed {
  width: $mainContentWidth;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.expand {
  font-size: 13px;
  padding-top: 10px;
}

@media (max-width: $mainContentWidth) {
  .root {
    margin-left: 0;
    margin-right: 0;
  }
}
